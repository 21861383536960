<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：行政管理 > <b>业务量统计</b></span>
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <el-date-picker
                        style="width: 260px"
                        v-model="start_end_time"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <el-button class="margin-left-xs" type="primary" @click="search">搜索</el-button>
                </div>
                <div class="flex-center">
                    <el-button type="primary" @click="dialogVisible = true">设置人员</el-button>
                    <el-button type="primary" @click="tableExport">导出</el-button>
                </div>
            </div>
            <div class="project-list">
                <div class="margin-bottom-sm">{{ time }}</div>
                <el-table
                    :data="tableData"
                    header-row-class-name="table-header"
                    style="width: 100%">
                    <el-table-column
                        label="序号"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <div class="table-id">
                                {{ scope.$index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="姓名"
                        width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.true_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="招商资料">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.zhao_shang }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="上游协议">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.shang_you }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="下游协议">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.xia_you }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="供应商比价">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.bi_jia }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="特殊审批">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.te_shu_shen_pi }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="特殊签批">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.traffic.te_shu_qian_pi }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="选择人员" :visible.sync="dialogVisible">
            <div class="dialog-members">
                <div class="master-name flex flex-direction align-center justify-center"
                     v-for="item in members"
                     :key="item.user_id">
                    <div>{{ item.true_name }}</div>
                    <el-switch class="margin-top-sm" v-model="item.is_show_record"
                               :active-value="1"
                               :inactive-value="0"
                               :active-text="item.is_show_record ? '显示' : '不显示'"
                               inactive-text="" @change="changeState(item)"></el-switch>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import config from "../../config";

export default {
    name: "businessStatistics",
    data() {
        return {
            start_end_time: [],
            tableData: [],
            time: '',
            page: 1,
            pageTotal: 1,
            limit: 12,
            dialogVisible: false,
            members: false
        }
    },
    created() {
        this.$emit('setIndex', [5, 5])
        this.init()
        this.getUserList()
    },
    methods: {
        async getUserList() {
            this.$api.apiContent
                .getShowRecordQuery({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.members = res.msg
                })
                .catch(() => {
                    this.members = [];
                });
        },
        //  搜素
        search() {
            this.page = 1
            this.init()
        },
        // 分页变化
        setPage(val) {
            this.page = val
            this.init()
        },
        //  页面加载时请求
        init() {
            this.$api.apiContent.statistics_traffic({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                search_date: this.start_end_time.join('~'),
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    search_date: this.start_end_time.join('~'),
                }, this.$store.state.user.key)
            }).then(res => {
                this.tableData = res.data.query
                this.time = res.data.date
            }).catch(() => {
                this.tableData = []
            })
        },
        tableExport() {
            let that = this
            that.$api.apiContent.statistics_traffic({
                token: that.$store.state.user.token,
                timestamp: that.$tools.requestTime(),
                name: this.search_content,
                is_export: 1,
                search_date: this.start_end_time.join('~'),
                sign: that.$tools.SHA256({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    name: this.search_content,
                    is_export: 1,
                    search_date: this.start_end_time.join('~'),
                }, that.$store.state.user.key)
            }).then(res => {
                window.location.href = config.urlStr + '/' + res.data
            }).catch((e) => {
                that.$message.error(e.msg);
            })
        },
        changeState(item) {
            let that = this
            that.$api.apiContent.setShowRecord({
                token: that.$store.state.user.token,
                timestamp: that.$tools.requestTime(),
                user_id: item.user_id,
                is_show_record: item.is_show_record,
                sign: that.$tools.SHA256({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    user_id: item.user_id,
                    is_show_record: item.is_show_record,
                }, that.$store.state.user.key)
            }).then(res => {
                that.$message.success(res.msg);
                that.init()
            }).catch((e) => {
                that.$message.error(e.msg);
            })
        },
    },
    watch: {}

}
</script>

<style scoped lang="scss">
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }

        }
    }

    .project-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        overflow: hidden;

        .project-condition {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 28px 16px;
            border-bottom: 2px solid #f5f5f5;

            .title-tab {
                .active {
                    background: #3b77e7;
                    color: #fff;
                }
            }

            .el-select {
                margin-right: 20px;
            }

            .el-input {
                margin: 0 20px;
                width: 268px;
            }
        }

        .project-list {
            padding: 34px 28px;
            min-height: 700px;

            .table-id {
                text-align: center;
                font-size: 16px;
                color: #666666;
            }

            .table-item {
                text-align: center;
                font-size: 16px;
                color: #666666;
            }

            .table-operate {
                display: flex;
                flex-direction: column;
                text-align: center;
                font-size: 16px;
                color: #666666;

                .el-button {
                    margin-left: 0px;
                    font-size: 16px;
                }
            }

            .page-all {
                text-align: center;
                padding: 30px 0 0;
            }
        }
    }

    .dialog-members {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .master-name {
            width: 135px;
            border: 1px solid #eeeeee;
            padding: 10px;
        }
    }
}
</style>